import request from '@/utils/request'

class PlaceResource {
  // ======================== 新 ==========================
  /**
   * 获取网点列表
   * @param {*} params 查询条件
   * @param {*} page 页数
   * @param {*} size 条数
   * @returns
   */
  async placeResourceList(params, page = 1, size = 10) {
    return await request({
      url: `/apis/place/page/${page}/size/${size}`,
      method: 'get',
      params
    })
  }
  /**
   * 获取网点设备总数
   * @param {*} params 查询条件
   * @returns
   */
  async placeResourceCount(params) {
    return await request({
      url: `/apis/equip/countings`,
      method: 'get',
      params
    })
  }
  /**
   * 获取网点详情
   * @param {*} id 网点id
   * @returns
   */
  async placeResourceDetail(id) {
    return await request({
      url: `/apis/place/id/${id}`,
      method: 'get'
    })
  }
  /**
   * 新增网点
   * @param {*} data表单信息
   * @returns
   */
  async placeResourceInfo(data) {
    return await request({
      url: `/apis/place`,
      method: 'post',
      data
    })
  }
  /**
   * 获取网点修改
   * @param {*} id 网点id
   * @param {*} data 表单信息
   * @returns
   */
  async updatePlaceResource(id, data) {
    return await request({
      url: `/apis/place/id/${id}`,
      method: 'put',
      data
    })
  }
  /**
   * 网点总数
   * @returns
   */
  async branchesCountings() {
    return await request({
      url: `/apis/place/countings`,
      method: 'get'
    })
  }
  //  网点类型列表
  async placeTypeList() {
    return await request({
      url: `/apis/place/type`,
      method: 'get'
    })
  }
  // ======================== 旧 ============================
}
export default new PlaceResource()
