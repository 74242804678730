/*
 * @Author: your name
 * @Date: 2021-12-01 16:06:57
 * @LastEditTime: 2022-08-09 11:18:33
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /webwechatpublicend/src/permission.js
 */

import Vue from 'vue'
import router from '@/router'
import { getToken, setUserInfo, getUserInfo, removeToken } from '@/utils/auth'
import UserApi from 'api/user/user'
import store from '@/store'
import WebSocket from '@/utils/webSocket'
import { Toast } from 'vant'
import Wechat from '@/utils/wechat'

// NProgress.configure({ showSpinner: false })// NProgress Configuration

const whiteList = ['/login']// no redirect whitelist
let toPath = ''

// DONE: 过滤请求路径
router.beforeEach((to, from, next) => {
  // 检查是否存在 token
  if (getToken()) {
    // 如果有token（登录成功后），才订阅频道
    if (Vue.prototype.$socket === undefined) {
      Vue.prototype.$socket = new WebSocket()
      // 初始化wx
      const wechat = new Wechat()
      wechat.getWxToken()
    }
    if ((from.path === '/propertyPlate' || from.path === '/safetyCommitteePlate' || from.path === '/iotApp') && to.path === '/hazard') {
      to.meta.keepAlive = false
    } else if (from.path === '/aboutEquip' && to.path === '/hazard') {
      to.meta.keepAlive = false
    } else if (from.path === '/airSwitch' && to.path === '/routeTiming') {
      to.meta.keepAlive = false
    } else if (from.path === '/routeTiming' && to.path === '/routeTimingDetails') {
      from.meta.keepAlive = true
    } else if (from.path === '/placeResourceDetail') {
      to.meta.keepAlive = !to.path === '/placeResourceEditorial'
    } else if (to.path === '/trajectoryByEquip') {
      to.meta.title = to.query.equipName
    } else if (to.path === '/addOrEditSelfCheckTask') {
      to.meta.title = to.query.pageTitle
    }

    if (!getUserInfo()) {
      UserApi.personInfo().then(res => {
        if (res.code === 200) {
          // 缓存userInfo
          setUserInfo({ ...res.data })
        }
      })
    }
    // 跳过登陆
    if (to.path === '/login') {
      if (toPath.length <= 0 && store.getters.addRouters.length > 0) {
        toPath = store.getters.addRouters[0].path
      }
      return next({ path: toPath, query: to.query })
    } else {
      // 此处获取userInfo个人信息,检查权限信息是否存在
      if (store.getters.permissions.length === 0) {
        // 加载权限 (这里应该是请求后端接口, 进行加载权限列表) 下面手动 set到对应的值内了
        let permissions = []
        UserApi.permissionList().then(
          res => {
            if (res.code === 200) {
              permissions = res.data
              if (permissions.length === 0) {
                Toast('您已登录成功，暂无权限访问任何页面，有问题请联系管理员')
                removeToken()
                return next('/404')
              }
              // ['NetworkApplication', '0', '1', '2', '4']
              store.commit('SET_PERMISSIONS', permissions)
              // 初始化权限对应的路由到动态路由当中
              store.dispatch('GenerateRoutes', { permissions }).then(() => {
                store.getters.addRouters.forEach(item => {
                  router.addRoute(item)
                })
                if (store.getters.addRouters.length > 0) {
                  toPath = ((store.getters.addRouters[0].children && store.getters.addRouters[0].children.length > 0) ? store.getters.addRouters[0].children[0].path : store.getters.addRouters[0].path)
                  // next({ path: toPath, replace: true }) // 临时代码
                  next({ path: from.path === '/' && to.path !== '/' && to.path !== '/404' ? to.path : toPath, replace: true, query: to.query })
                } else {
                  next({ path: '/404' })
                }
              })
            } else {
              next({ path: '/404' })
            }
          }, rej => {
            next()
          })
      } else {
        next()
      }
    }
  } else {
    if (to.meta.reserveSystem) {
      if (to.path === from.path) {
        next({ path: to.path })
      } else {
        next()
      }
    } else if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      next('/login')
    }
  }
})

router.afterEach(() => {
  // NProgress.done()
})
