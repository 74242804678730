/*
 * @Author: your name
 * @Date: 2021-08-13 08:49:17
 * @LastEditTime: 2021-08-13 09:06:11
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /webwechatpublicend/src/utils/extra.js
 */
import Vue from 'vue'
import config from '../../config.json'
import store from '../store'

Vue.directive('permission', {
  inserted: (el, binding, vnode) => {
    // DONE: 截取自定义组件 component的 name
    let componentName = ''
    if (vnode.tag.indexOf('-') !== -1) {
      const cmpArray = vnode.tag.split('-')
      componentName = cmpArray[cmpArray.length - 1]
    } else {
      componentName = vnode.tag
    }
    // DONE: 满足部分需要父子组件一同管理的组件 (非固化父子关系的组件, 不推荐使用)
    if (binding.arg === 'parent') {
      componentName = `${binding.value}-${componentName}`
    }

    // DONE: 获取配置文件中的组件库

    const components = config.router.components

    // DONE: 检查组件是否在组件库中
    if (Reflect.has(components, componentName)) {
      // 获取所需要的权限 (角色)
      const { permissions } = Reflect.get(components, componentName)
      // 获取当前用户拥有的权限 (角色)
      const _permissions = store.getters.permissions

      // DONE: 组件权限全匹配
      //  permissions.every(permission => [..._permissions].includes(permission))
      // DONE: 组件权限部分匹配
      //  permissions.some(permission => [..._permissions].includes(permission))

      // 当前版本取全匹配权限
      if (vnode.data.attrs.matchingType) {
        const res = permissions[vnode.data.attrs.matchingType](permission => [..._permissions].includes(permission))
        // 检查有没有存在超权限 (角色)的情况
        const isSuperAdmin = _permissions.some(role => role === 'SuperAdmin')

        // 如果是超权限 (角色), 则不进行过滤处理~
        if (!isSuperAdmin) {
          if (!res) {
            el.parentNode.removeChild(el)
          }
        }
      }
    } else {
      // el.parentNode.removeChild(el)
      // console.warn('当前启用权限过滤的组件, 不在组件库中, 请检查是否配置错误! ')
    }
  }
})

// const stopEvent = el => {
//   el.addEventListener('click', (e) => {
//     e.stopImmediatePropagation()
//   }, true)
// }
