/*
 * @Description:
 */
import Stomp from 'stompjs'
import Config from '/config.json'
import DepartApi from 'api/depart/depart'
import { getToken } from '@/utils/auth'
import store from '@/store'

let socket = null
const topic = { event: '/exchange/aiot-event-message/',
  count: '/exchange/aiot-counting-message/',
  digitalSentryEvent: '/exchange/antiepileptic-event-message/',
  antiepilepticCounting: '/exchange/antiepileptic-counting-message/',
  airSwitchBrakeWarningEvent: '/exchange/air-switch-line-status-change-message/', // 智能空开-分合闸警示消息
  leakageProtectInspectionEvent: '/exchange/leakage-protect-inspection-message/' // 智能空开-漏报自检弹窗提醒
}
let topicName = ''
let requestPath = ''
let loading = false
let departList = []
let todoTopicList = []
let doneTopicList = []
// const id = ''
const topicList = []
class Socket {
  constructor() {
    requestPath = Config.webSocketInfo.url
    this.accountName = Config.webSocketInfo.account
    this.passWord = Config.webSocketInfo.password
  }
  async initSocket(e) {
    /**
     * done用来装已经订阅过的频道，todo是要订阅的
     * 传入的参数分别为两个频道event事件推送和count实时推送
     * 如果done中已经有某个频道了 不加载todo中，没有则加入 执行完后清空todo数组
     */
    if (doneTopicList.indexOf(e) === -1 && todoTopicList.indexOf(e) === -1 && (e === 'count' || e === 'event' || e === 'digitalSentryEvent' || e === 'antiepilepticCounting' || e === 'airSwitchBrakeWarningEvent' || e === 'leakageProtectInspectionEvent')) {
      todoTopicList.push(e)
      doneTopicList.push(e)
    }
    if (todoTopicList.length > 0) {
      topicName = e
      if ((departList.length < 1 || !departList) && getToken()) {
        const res = await DepartApi.departList()
        if (res.code === 200) {
          departList = res.data
        }
      }
      if (!loading && !socket) {
        loading = true
        socket = new WebSocket(requestPath)
        socket = Stomp.over(socket)
        // 设置发送频率
        socket.heartbeat.outgoing = 1
        // 接收频率
        socket.heartbeat.incoming = 0
        // 关闭输出
        socket.debug = false
        await socket.connect(this.accountName, this.passWord, this.onConnected, () => {
          socket = null
          loading = false
          todoTopicList = JSON.parse(JSON.stringify(doneTopicList))
          doneTopicList = []
          this.initSocket(topicName)
        })
      } else if (socket) {
        this.onConnected()
      }
    }
  }
  onConnected() {
    todoTopicList.forEach(item => {
      departList.forEach(departItem => {
        const topicLink = topic[item] + departItem.departId
        const temp = socket.subscribe(topicLink, msg => {
        // 事件回调触发 => 给对应频道的订阅节点分发信息
          if (item === 'event') {
            store.commit('SET_REALTIME_EVENT', JSON.parse(msg.body))
          } else if (item === 'count') {
            store.commit('SET_REALTIME_NUM', JSON.parse(msg.body))
          } else if (item === 'digitalSentryEvent') {
            store.commit('SET_ANTIEPILEPTIC_EVENT', JSON.parse(msg.body))
          } else if (item === 'antiepilepticCounting') {
            store.commit('SET_ANTIEPILEPTIC_COUNTING', JSON.parse(msg.body))
          } else if (item === 'airSwitchBrakeWarningEvent') {
            store.commit('SET_AIR_SWITCH_BRAKE_WARNING_EVENT', JSON.parse(msg.body))
          } else if (item === 'leakageProtectInspectionEvent') {
            store.commit('SET_LEAKAGE_PROTECT_INSPECTION_EVENT', JSON.parse(msg.body))
          }
        })

        const topicIndex = topicList.findIndex(topicItem => topicItem.name === item)
        if (topicIndex === -1) { // 没有这个频道的情况
          topicList.push({
            name: item,
            ids: [temp.id]
          })
        } else { // 有就往里面加id
          topicList[topicIndex].ids.push(temp.id)
        }
      })
    })
    loading = false
    todoTopicList = []
  }
  unsubscribe(name) {
    topicList.forEach(topic => {
      // 找到对应频道
      if (topic.name === name) {
        topic.ids.forEach(id => {
          socket.unsubscribe(id)
        })
        topic.ids = []
      }
    })
    doneTopicList = doneTopicList.filter(item => { return item !== name })

    // 旧
    // if (id !== '') {
    //   socket.unsubscribe(id)
    //   id = ''
    //   doneTopicList = doneTopicList.filter(item => { return item !== 'count' })
    // }
  }
}
export default Socket
