var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "adaptive",
      style: "width:" + _vm.size[0] + ";padding-bottom:" + _vm.size[1] + ";",
    },
    [_c("div", { staticClass: "inside-content" }, [_vm._t("default")], 2)]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }