import HazardApi from 'api/hazard/hazard.js'
import PlaceApi from 'api/place/place.js'
import AiotApi from 'api/aiot/aiot.js'
import PlatformApi from 'api/platform/platform.js'
import UserApi from 'api/user/user.js'
import DigitalSentryApi from '@/api/application/digitalSentry/digitalSentry.js'
const typeList = [
  // 隐患类型列表
  'hazardType',
  // 网点类型列表
  'placeType',
  // 设备类型列表
  'equipType',
  // 物联网平台列表
  'platformList',
  // 国标类型
  'nationalStandardType',
  // 账单类型
  'billType'
]

const persistence = {
  state: {
    // 当前页面加载状态
    loadingStatus: false,
    // 隐患类型列表
    hazardType: [],
    // 网点类型列表
    placeType: [],
    // 设备类型列表
    equipType: [],
    // 物联网平台列表
    platformList: [],
    // 国标
    nationalStandardType: [],
    // 账单类型列表
    billType: [],
    // 智慧应用树形网格数据
    intelligentAppDepartTree: []
  },
  mutations: {
    SET_LOADING_STATUS: (state, data) => { // 当前页面加载状态
      state.loadingStatus = data
    },
    SET_HAZARD_TYPE: (state, data) => { // 隐患类型列表
      state.hazardType = data
    },
    SET_PLACE_TYPE: (state, data) => { // 网点类型列表
      state.placeType = data
    },
    SET_EQUIP_TYPE: (state, data) => { // 设备类型列表
      state.equipType = data
    },
    SET_PLATFORM_LIST: (state, data) => { // 设备类型列表
      state.platformList = data
    },
    SET_NATIONAL_STANDARD_TYPE: (state, data) => { // 设备类型列表
      state.nationalStandardType = data
    },
    SET_BILL_TYPE: (state, data) => { // 账单类型列表
      state.billType = data
    },
    SET_INTELLIGENT_APP_DEPART_TREE: (state, data) => { // 写入智慧应用树形网格数据
      state.intelligentAppDepartTree = data
    }
  },
  actions: {
    /**
     * 根据变量名发送请求获取对应的数据
     * @param {*} param0
     * @param {*} params
     */
    async getDataByHttp({ commit }, params) {
      // 生成相应的请求方法集合，生成的requests只要调用相应属性的方法即可
      // 判断如果没有值才去请求
      const condition = [null, undefined, ''] // 判断的条件
      if (!condition.includes(persistence.state[params.type]) && persistence.state[params.type].length < 1) {
        const requests = genRequest(commit, params) // 使用传送进来的类型去获取参数
        await requests[params.type]()
      }
    },
    // async getDataByHttp({ commit }, params) {
    //   let result = []
    //   const condition = [null, undefined, ''] // 判断的条件
    //   // 判断如果没有值才去请求
    //   if (!condition.includes(persistence.state[params.type]) && persistence.state[params.type].length < 1) {
    //     if (params.type === 'hazardType') {
    //       result = await HazardApi.hazardTypeList(0)
    //       if (result.code === 200) {
    //         commit('SET_HAZARD_TYPE', result.data)
    //       }
    //     } else if (params.type === 'placeType') {
    //       result = await PlaceApi.placeTypeList()

    //       if (result.code === 200) {
    //         commit('SET_PLACE_TYPE', result.data)
    //       }
    //     } else if (params.type === 'equipType') {
    //       result = await AiotApi.equipTypeList(0)
    //       if (result.code === 200) {
    //         commit('SET_EQUIP_TYPE', result.data)
    //       }
    //     } else if (params.type === 'platformList') {
    //       result = await PlatformApi.platformList()
    //       if (result.code === 200) {
    //         commit('SET_PLATFORM_LIST', result.data)
    //       }
    //     } else if (params.type === 'nationalStandardType') {
    //       result = await AiotApi.nationalStandardTypeList(0)
    //       if (result.code === 200) {
    //         commit('SET_NATIONAL_STANDARD_TYPE', result.data)
    //       }
    //     } else if (params.type === 'billType') {
    //       result = await UserApi.billTypeList()
    //       if (result.code === 200) {
    //         commit('SET_BILL_TYPE', result.data)
    //       }
    //     } else if (params.type === 'intelligentAppDepartTree') {
    //       result = await DigitalSentryApi.departList(params.systemTypeId)
    //       if (result.code === 200) {
    //         commit('SET_INTELLIGENT_APP_DEPART_TREE', result.data)
    //       }
    //     }
    //   }
    // },
    /**
     * 查询全部需要存到vuex的数据
     * @param {*} state
     */
    async generatePersistence({ commit }) {
      // 如果页面加载完成，遍历需要请求的值去请求
      typeList.forEach(param => {
        persistence.actions.getDataByHttp({ commit }, param)
      })
    }
  }
}

/**
 * @description:
 * @param {*} commit
 * @param {*} data
 * @return {*}
 */
const genRequest = (commit, data) => {
  // 供requestByType发送请求的第三个参数（param）
  const thatData = data
  // 柯里化生成相应函数
  const requestByType = (typeRequest, commitType, param) => {
    return async() => {
      const reqParam = param === undefined ? undefined : param
      const result = await typeRequest(reqParam) // 通过下面return的对象里面，配置的api名称去进行请求
      if (result.code === 200) {
        commit(commitType, result.data)
      }
    }
  }

  return {
    hazardType: requestByType(HazardApi.hazardTypeList, 'SET_HAZARD_TYPE', 0), // 隐患类型列表
    placeType: requestByType(PlaceApi.placeTypeList, 'SET_PLACE_TYPE'), // 网点类型列表
    equipType: requestByType(AiotApi.equipTypeList, 'SET_EQUIP_TYPE', 0), // 设备类型列表
    platformList: requestByType(PlatformApi.platformList, 'SET_PLATFORM_LIST'), // 平台类型列表
    nationalStandardType: requestByType(AiotApi.nationalStandardType, 'SET_NATIONAL_STANDARD_TYPE', 0), // 获取子设备类型（国标类型）
    billType: requestByType(UserApi.billTypeList, 'SET_BILL_TYPE'), // 账单类型
    intelligentAppDepartTree: requestByType(DigitalSentryApi.departTree, 'SET_INTELLIGENT_APP_DEPART_TREE', thatData.systemTypeId) // 网格树列表
  }
}

export default persistence
