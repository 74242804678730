/*
 * @Description:
 */
const socket = {
  // data
  state: {
    realtimeNum: [],
    realtimeEvent: [],
    antiepilepticEvent: [],
    antiepilepticCounting: [],
    airSwitchBrakeWarningEvent: [],
    leakageProtectInspectionEvent: []
  },
  // view
  mutations: {
    SET_REALTIME_NUM: (state, realtimeNum) => { // 更新 实时数据
      state.realtimeNum = realtimeNum
    },
    SET_REALTIME_EVENT: (state, realtimeEvent) => { // 更新事件数据
      state.realtimeEvent = realtimeEvent
    },
    SET_ANTIEPILEPTIC_EVENT: (state, realtimeEvent) => { // 数字哨兵实时数据
      state.antiepilepticEvent = realtimeEvent
    },
    SET_ANTIEPILEPTIC_COUNTING: (state, realtimeEvent) => { // 数字哨兵实时数据
      state.antiepilepticCounting = realtimeEvent
    },
    SET_AIR_SWITCH_BRAKE_WARNING_EVENT: (state, airSwitchBrakeWarningEvent) => { // 智能空开分合闸警示
      state.airSwitchBrakeWarningEvent = airSwitchBrakeWarningEvent
    },
    SET_LEAKAGE_PROTECT_INSPECTION_EVENT: (state, leakageProtectInspectionEvent) => { // 智能空开漏保自检弹窗提示信息
      state.leakageProtectInspectionEvent = leakageProtectInspectionEvent
    }
  },
  // method
  actions: {

  }
}

export default socket
