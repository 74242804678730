import Cookies from 'js-cookie'

const TokenKey = 'aiot-token-wx'

const UserInfo = 'user-info-wx'

const AlarmModelSwitch = 'alarm-model-switch' // 报警弹窗标识

const CurrentScreen = 'current-screen' // 0 物联网感知大屏 1 健康码监测大屏

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token, data) {
  return new Promise(resolve => {
    Cookies.set(TokenKey, token, { expires: data })
    resolve()
  })
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function setUserInfo(userInfo) {
  return Cookies.set(UserInfo, userInfo)
}

export function getUserInfo() {
  return Cookies.get(UserInfo) ? JSON.parse(Cookies.get(UserInfo)) : null
}

export function removeUserInfo() {
  return Cookies.remove(UserInfo)
}

export function setAlarmModelSwitch(open) {
  return Cookies.set(AlarmModelSwitch, open)
}

export function getAlarmModelSwitch() {
  if (Cookies.get(AlarmModelSwitch) === null || Cookies.get(AlarmModelSwitch) === undefined) {
    Cookies.set(AlarmModelSwitch, true)
  }
  return Cookies.get(AlarmModelSwitch) ? JSON.parse(Cookies.get(AlarmModelSwitch)) : null
}

export function getCurrentScreen() {
  if (Cookies.get(CurrentScreen) === null || Cookies.get(CurrentScreen) === undefined) {
    Cookies.set(CurrentScreen, 0) // 默认为物联网监测大屏
  }
  return Cookies.get(CurrentScreen)
}
export function setCurrentScreen(id) {
  return Cookies.set(CurrentScreen, id)
}
