/*
 * @Description: 智慧应用-哨兵管理(哨兵管理)子系统api
 */
import request from '@/utils/request'
// apis
// import DigitalSentry from '@/api/application/digitalSentry/digitalSentry.js'
class DigitalSentry {
  /** ======================== 网点网格  =========================== */
  /**
   * @description: 网格列表（下拉框option）
   * @param {*} systemTypeId 系统id
   * @return {*}
   */
  async departTree(systemTypeId) {
    return await request({
      url: `/apis/application/system/type/${systemTypeId}/depart/tree`,
      method: 'get'
    })
  }
  /**
   * @description: 网点列表（下拉框option）
   * @param {*} systemTypeId 系统id
   * @return {*}
   */
  async placeList(systemTypeId, params) {
    return await request({
      url: `/apis/application/system/type/${systemTypeId}/network/list`,
      method: 'get',
      params
    })
  }
  /**
   * @description: 哨兵安装位置
   * @param {*} systemTypeId
   * @return {*}
   */
  async sentryInstallAddress(systemTypeId, params) {
    return await request({
      url: `/apis/application/system/type/${systemTypeId}/install/address/list`,
      method: 'get',
      params
    })
  }
  /** ======================== 哨兵管理 1 =========================== */
  /**
   * @description: 获取数字哨兵管理列表
   * @param {*} systemTypeId 系统id
   * @param {*} params Query params
   * @param {*} page 页
   * @param {*} size 页面列表条数
   * @return {*}
   */
  async sentryManageList(systemTypeId, params, page = 1, size = 10) {
    return await request({
      url: `/apis/application/system/type/${systemTypeId}/manager/list/page/${page}/size/${size}`,
      method: 'get',
      params
    })
  }
  /**
   * @description: 哨兵管理 - 监测管理窗口的卡片编辑/批量编辑数据提交
   * @param {*} systemTypeId 系统id
   * @param {*} data body params
   * @return {*}
   */
  async monitorManageConfigCardUpdate(systemTypeId, data) {
    return await request({
      url: `/apis/application/system/type/${systemTypeId}/manager/monitor/manager`,
      method: 'put',
      data
    })
  }
  /**
   * @description: 通行管理编辑保存
   * @param {*} systemTypeId 系统id
   * @param {*} data body params
   * @return {*}
   */
  async trafficConfigData(systemTypeId, data) {
    return await request({
      url: `/apis/application/system/type/${systemTypeId}/manager/traffic/manager`,
      method: 'put',
      data
    })
  }
  /**
   * @description: 哨兵管理列表 - 导出
   * @param {*} systemTypeId 系统id
   * @param {*} params Query params
   * @return {*}
   */
  async sentryManageListOutput(systemTypeId, params) {
    return await request({
      responseType: 'arraybuffer',
      url: `/apis/application/system/type/${systemTypeId}/manager/list/export`,
      method: 'get',
      params
    })
  }
  /**
   * @description: 哨兵管理 - 监测管理窗口 - 顶部的网点信息
   * @param {*} systemTypeId 系统id
   * @param {*} networkId 网点id
   * @return {*}
   */
  async networkInfoOfMonitorManage(systemTypeId, networkId) {
    return await request({
      url: `/apis/application/system/type/${systemTypeId}/manager/network/id/${networkId}`,
      method: 'get'
    })
  }
  /**
   * @description: 哨兵管理 - 监测管理 - 网点关联的设备（卡片）信息
   * @param {*} systemTypeId 系统id
   * @param {*} networkId 网点id
   * @param {*} params Query params
   * @return {*}
   */
  async equipInfoOfMonitorManage(systemTypeId, networkId, params) {
    return await request({
      url: `/apis/application/system/type/${systemTypeId}/manager/equip/info/network/id/${networkId}`,
      method: 'get',
      params
    })
  }
  /**
   * @description: 哨兵管理 - 通行管理 - 初始化信息查询
   * @param {*} systemTypeId 系统id
   * @param {*} networkId 网点id
   * @return {*}
   */
  async passageManageInfo(systemTypeId, networkId) {
    return await request({
      url: `/apis/application/system/type/${systemTypeId}/manager/traffic/manager/network/id/${networkId}`,
      method: 'get'
    })
  }
  /** ======================== 人员分组 2 =========================== */
  /**
   * @description: 人员分组列表(左侧)
   * @param {*} systemTypeId
   * @param {*} params
   * @param {*} page
   * @param {*} size
   * @return {*}
   */
  async personGroupList(systemTypeId, params, page, size) {
    return await request({
      url: `/apis/application/system/type/${systemTypeId}/group/people/list/page/${page}/size/${size}`,
      method: 'get',
      params
    })
  }
  /**
   * @description: 获取人员分组-成员列表
   * @param {*} systemTypeId 系统id
   * @param {*} groupPeopleId 分组ID
   * @param {*} params Query params
   * @param {*} page 页面
   * @param {*} size 页面数据条数
   * @return {*}
   */
  async personGroupMemberList(systemTypeId, groupPeopleId, params, page, size) {
    return await request({
      url: `/apis/application/system/type/${systemTypeId}/group/people/group/id/${groupPeopleId}/people/list/page/${page}/size/${size}`,
      method: 'get',
      params
    })
  }
  /**
   * @description: 人员分组列表（下拉框专用）
   * @param {*} systemTypeId 系统id
   * @param {*} params Query params
   * @return {*}
   */
  async personGroupListPicker(systemTypeId, params) {
    return await request({
      url: `/apis/application/system/type/${systemTypeId}/group/people/list/picker`,
      method: 'get',
      params
    })
  }
  /**
   * @description: 修改人员分组 （名字）
   * @param {*} systemTypeId 系统id
   * @param {*} groupPeopleId 分组id
   * @return {*}
   */
  async personGroupEdit(systemTypeId, groupPeopleId, data) {
    return await request({
      url: `/apis/application/system/type/${systemTypeId}/group/people/id/${groupPeopleId}`,
      method: 'put',
      data
    })
  }
  /**
   * @description: 添加人员分组
   * @param {*} systemTypeId 系统id
   * @param {*} data body params
   * @return {*}
   */
  async personGroupAdd(systemTypeId, data) {
    return await request({
      url: `/apis/application/system/type/${systemTypeId}/group/people`,
      method: 'post',
      data
    })
  }
  /**
   * @description: 删除人员分组
   * @param {*} systemTypeId 系统id
   * @param {*} groupPeopleId 分组id
   * @return {*}
   */
  async personGroupDelete(systemTypeId, groupPeopleId) {
    return await request({
      url: `/apis/application/system/type/${systemTypeId}/group/people/id/${groupPeopleId}`,
      method: 'delete'
    })
  }
  /**
   * @description: 人员分组-添加成员
   * @param {*} systemTypeId 系统id
   * @param {*} groupPeopleId 分组id
   * @param {*} data body params
   * @return {*}
   */
  async memberAdd(systemTypeId, groupPeopleId, data) {
    return await request({
      url: `/apis/application/system/type/${systemTypeId}/group/people/group/id/${groupPeopleId}/people`,
      method: 'post',
      data
    })
  }
  /**
   * @description: 人员分组-修改成员（编辑）
   * @param {*} systemTypeId 系统id
   * @param {*} peopleId 人员id
   * @param {*} data body params
   * @return {*}
   */
  async memberEdit(systemTypeId, peopleId, data) {
    return await request({
      url: `/apis/application/system/type/${systemTypeId}/group/people/people/id/${peopleId}`,
      method: 'put',
      data
    })
  }
  async memberStatusChange(systemTypeId, groupPeopleId, data) {
    return await request({
      url: `/apis/application/system/type/${systemTypeId}/group/people/group/id/${groupPeopleId}/enable/state`,
      method: 'put',
      data
    })
  }
  /**
   * @description: 人员分组-删除成员
   * @param {*} systemTypeId
   * @param {*} peopleId
   * @return {*}
   */
  async memberDelete(systemTypeId, peopleId) {
    return await request({
      url: `/apis/application/system/type/${systemTypeId}/group/people/people/id/${peopleId}`,
      method: 'delete'
    })
  }

  /** ======================== 通行记录 3 =========================== */
  /**
   * @description: 通行记录列表
   * @param {*} systemTypeId
   * @param {*} data
   * @param {*} page
   * @param {*} size
   * @return {*}
   */
  async passageRecordList(systemTypeId, data, page = 1, size = 10) {
    return await request({
      url: `/apis/application/system/type/${systemTypeId}/personnel/current/record/list/page/${page}/size/${size}`,
      method: 'post',
      data
    })
  }
  /**
   * @description: 通行记录详情
   * @param {*} systemTypeId
   * @param {*} recordId
   * @return {*}
   */
  async passageRecordDetail(systemTypeId, recordId) {
    return await request({
      url: `/apis/application/system/type/${systemTypeId}/personnel/current/id/${recordId}/record/info`,
      method: 'get'
    })
  }
  /** ======================== 预约记录 4 =========================== */
  /**
   * @description: 预约记录列表
   * @param {*} systemTypeId
   * @param {*} data
   * @param {*} page
   * @param {*} size
   * @return {*}
   */
  async reserveRecordList(systemTypeId, data, page = 1, size = 10) {
    return await request({
      url: `/apis/application/system/type/${systemTypeId}/appointment/list/page/${page}/size/${size}`,
      method: 'post',
      data
    })
  }
  /**
     * @description: 预约详情
     * @param {*} systemTypeId
     * @param {*} data
     * @param {*} page
     * @param {*} size
     * @return {*}
     */
  async reserveRecordDetail(systemTypeId, recordId) {
    return await request({
      url: `/apis/application/system/type/${systemTypeId}/appointment/detail/id/${recordId}`,
      method: 'get'
    })
  }
  /**
   * @description: 预约审核
   * @param {*} systemTypeId
   * @param {*} recordId
   * @return {*}
   */
  async reserveCheck(systemTypeId, recordId, data) {
    return await request({
      url: `/apis/application/system/type/${systemTypeId}/appointment/status/id/${recordId}`,
      method: 'put',
      data
    })
  }
  /**
   * @description: 获取预约记录审核日志列表
   * @param {*} systemTypeId
   * @param {*} reserveId
   * @return {*}
   */
  async reserveCheckLogList(systemTypeId, reserveId) {
    return await request({
      url: `/apis/application/system/type/${systemTypeId}/appointment/status/log/id/${reserveId}`,
      method: 'get'
    })
  }
}
export default new DigitalSentry()
