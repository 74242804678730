import request from '@/utils/request'

class Platform {
  /**
   * 物联网平台类型列表
   * @returns
   */
  async platformList() {
    return await request({
      url: `/apis/equip/platform/list`,
      method: 'get'
    })
  }
}
export default new Platform()
