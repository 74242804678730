import store from '../store'
const getters = {
  permission_routers: state => state.permission.routers,
  menu_data: state => state.equip.menuData,
  active_equiptype: state => state.equip.activeEquipType,
  addRouters: state => state.permission.addRouters,
  nextPath: state => state.permission.addRouters[0],
  roles: state => state.user.roles,
  permissions: state => state.user.permissions,
  hazardType: state => get(state, 'hazardType'),
  placeType: state => get(state, 'placeType'),
  equipType: state => get(state, 'equipType'),
  platformList: state => get(state, 'platformList'),
  nationalStandardType: state => get(state, 'nationalStandardType'),
  billType: state => get(state, 'billType'),
  applicationEquipList: state => state.index.applicationEquipList,
  activeCollapseName: state => state.video.activeCollapseName,
  menus: state => state.permission.menus,
  realtimeNum: state => state.socket.realtimeNum,
  realtimeEvent: state => state.socket.realtimeEvent,
  antiepilepticEvent: state => state.socket.antiepilepticEvent,
  antiepilepticCounting: state => state.socket.antiepilepticCounting,
  airSwitchBrakeWarningEvent: state => state.socket.airSwitchBrakeWarningEvent,
  leakageProtectInspectionEvent: state => state.socket.leakageProtectInspectionEvent,
  intelligentAppDepartTree: state => systemTypeId => get(state, 'intelligentAppDepartTree', { 'systemTypeId': systemTypeId })
}
/**
 * 判断对应的值是否为空，如果有直接返回。如果没有就去获取
 * @param {*} state
 * @param {*} param
 * @returns
 */
async function get(state, param, source) {
  const params = { 'type': param, ...source }

  if (params.type.length > 0 && state.persistence[params.type].length > 0) {
    return state.persistence[params.type]
  } else {
    // 调用persistence的action的GeneratePersistence方法
    await store.dispatch('getDataByHttp', params)

    return state.persistence[params.type]
  }
}
export default getters
