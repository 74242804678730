import request from '@/utils/request'

class WeChat {
  /**
   * @name: 获取微信token
   * @return {type}
   */
  async getWeChatToken() {
    return await request({
      url: `/apis/wechat/ticket`,
      method: 'get'
    })
  }
  async getWxOpenId(params) {
    return await request({
      url: `/apis/wechat/code`,
      method: 'post',
      params
    })
  }
}
export default new WeChat()
