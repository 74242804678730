import request from '@/utils/request'

class Hazard {
  // ======================== 新 ==========================
  /**
   * 智慧视觉统计折线图（30天事件数，故障数）
   * @param {*} systemId
   * @returns
   */
  async analysisTimeline(systemId) {
    return await request({
      url: `/apis/equip/type/${systemId}/heartbeat/analysis/timeline`,
      method: 'get'
    })
  }
  /**
   * 监测分析（全部）
   * @param {*} systemType 子系统类型
   * @returns
   */
  async monitorAnalysis(systemType) {
    return await request({
      url: `/apis/heartbeat/hazard/by/equip/type/${systemType}/analysis/latest`,
      method: 'get'
    })
  }
  /**
   * 隐患统计-隐患列表
   * @param {*} type 系统类型
   * @param {*} page 页数
   * @param {*} size 行数
   * @param {*} params param参数
   * @returns
   */
  async hazardList(type, page, size, params) {
    return await request({
      url: `/apis/heartbeat/equip/type/${type}/list/page/${page}/size/${size}`,
      method: 'get',
      params
    })
  }
  /**
   * 监测分析（1月）
   * @param {*} systemType 系统类型
   * @returns
   */
  async monitorAnalysisMonth(systemType) {
    return await request({
      url: `/apis/heartbeat/hazard/by/equip/type/${systemType}/analysis/latest/month/1`,
      method: 'get'
    })
  }
  /**
   * 监测分析（1年）
   * @param {*} systemType 系统类型
   * @returns
   */
  async monitorAnalysisYear(systemType) {
    return await request({
      url: `/apis/heartbeat/hazard/by/equip/type/${systemType}/analysis/latest/year/1`,
      method: 'get'
    })
  }
  /**
   * 获取隐患未读未处理按时间排序前十列表
   * @param {*} systemType 系统类型
   * @returns
   */
  async hiddenDangerList(systemType) {
    return await request({
      url: `/apis/heartbeat/event/undone/unread/lastest/list/size/${systemType}`,
      method: 'get'
    })
  }

  /**
   * 隐患详情
   * @param {*} id 隐患id
   * @returns
   */
  async hazardDeatilInfo(id) {
    return await request({
      url: `/apis/heartbeat/id/${id}`,
      method: 'get'
    })
  }
  /**
   * 获取隐患处理记录
   * @param {*} id 心跳id
   * @returns
   */
  async hazardDealInfo(id) {
    return await request({
      url: `/apis/log/heartbeat/id/${id}/result`,
      method: 'get'
    })
  }
  /**
   * 获取隐患类型
   * @param {*} type 系统类型(0 全部系统)
   * @returns
   */
  async hazardTypeList(type) {
    return await request({
      url: `/apis/heartbeat/hazard/type/list/equip/type/${type}`,
      method: 'get'
    })
  }
  /**
   * 添加处理记录
   * @param {*} id 心跳id
   * @param {*} data data参数
   * @returns
   */
  async postProcessRecord(id, data) {
    return await request({
      url: `/apis/log/heartbeat/id/${id}/result`,
      method: 'post',
      data
    })
  }
  /**
   * 上传文件
   * @param {*} data FormData参数
   * @returns
   */
  async updaHandlePic(data) {
    return await request({
      url: `/apis/file`,
      method: 'post',
      data
    })
  }
  /**
   * 账户未读隐患清除
   * @param {*} params 隐患id
   * @returns
   */
  async hazardIsRead(params) {
    return await request({
      url: `/apis/heartbeat/unread/msg`,
      method: 'delete',
      params
    })
  }
  /**
   * 隐患列表-用传装置
   * @param {*} page 页数
   * @param {*} size 一页10条
   * @param {*} params params参数
   * @returns
   */
  async transmissionHazard(page, size, params) {
    return await request({
      url: `/apis/heartbeat/equip/type/12/list/page/${page}/size/${size}`,
      method: 'get',
      params
    })
  }
  // 网格统计联动，当前选中网格的隐患分析（近15天）
  async troubleAnalysis(params) {
    return await request({
      url: `/apis/equip/heartbeat/trouble/latest/counting`,
      method: 'get',
      params
    })
  }
}
export default new Hazard()
