<template>
  <div class="layout">
    <van-overlay :show="show" z-index="99999999" :lock-scroll="true">
      <div :class="flashingBox" @click="toDetail(hazardMessage.id)">
        <!-- <van-icon name="cross" color="rgba(255, 254, 254, 1)" class="close-button" @click.stop="closeMessage()" /> -->
        <div class="content">
          <div class="content-left">
            <div class="unread" />
            <div class="icon-box">
              <img class="icon" :src="hazardMessage.pictureUrl && hazardMessage.pictureUrl!==null? (eventPicturePrefix + hazardMessage.pictureUrl) : require('@/assets/images/public/warning.png') " alt="">
            </div>
          </div>
          <div class="content-right">
            <div class="title">
              {{ hazardMessage.equipAddress }}
            </div>
            <div class="hazard-type">
              {{ hazardMessage.hazardTypeName }}
            </div>
            <div class="date">
              {{ changeDate(hazardMessage.createdTime/1000) }}
            </div>
          </div>
        </div>
        <div class="foot">
          <div :class="(hazardMessage.type !== '1' && hazardMessage.type !== '4' && hazardMessage.type !== '2') ? 'multiple' :'close'" @click.stop="closeMessage">
            关闭
          </div>
          <div v-if="muteStatus" :style="haveTheMute?'color:#FF1743;':'color:#6F85A2;'" class="mute" @click.stop="equipMute(hazardMessage.equipId)">
            消音
          </div>
        </div>
      </div>
    </van-overlay>
    <van-notify v-if="currentAirSwitchMessage && isAirSwitchPath" v-model="showAirSwitchMessage" :background="currentAirSwitchMessage.airSwitchBackground" @click="handleMessageClick(currentAirSwitchMessage)">
      <div class="message-container">
        <div class="left">
          <img :src="currentAirSwitchMessage.leftIconImg" alt="">
        </div>
        <div class="center">
          <div class="title">
            {{ currentAirSwitchMessage.title }}
          </div>
          <div class="time-and-address">
            <span class="time">{{ currentAirSwitchMessage.time }}</span><span class="address">{{ currentAirSwitchMessage.address }}</span>
          </div>
          <div v-if="currentAirSwitchMessage.lines" class="lines">
            {{ currentAirSwitchMessage.lines }}
          </div>
        </div>
        <div class="right">
          <van-icon name="cross" @click.stop="closeAirSwitchMessage" />
        </div>
      </div>
    </van-notify>

    <!-- 空开消息：当页面处于编辑或增加漏保自检定时任务时,确认点击当前信息是否要进行跳转-->
    <van-dialog
      v-model="showAirSwitchMessageJump"
      class="layout-air-switch-dialog"
      title="您编辑内容未保存，是否确定跳转页面？"
      show-cancel-button
      @confirm="confirmAirSwitchJump"
      @cancel="showAirSwitchMessageJump=false"
    />
    <slot />
  </div>
</template>

<script>
// apis
import AiotApi from 'api/aiot/aiot'
// utils
import DateTransformation from '@/utils/dateTransformation.js'
import { getAlarmModelSwitch } from '@/utils/auth.js'
import Config from '/config.json'
// plugins
import { mapGetters } from 'vuex'
import ReadTypeNameOnVuex from '@/utils/readTypeNameOnVuex'
export default {
  name: 'Layout',
  components: {
  },
  props: {

  },
  data() {
    return {
      show: false,
      hazardMessage: {},
      flashingTimer: 0,
      flashingBox: 'hazard-message',
      hazardList: [],
      working: false,
      waitTime: 0,
      muteStatus: false,
      haveTheMute: false,
      eventPicturePrefix: '',
      dateTransformation: new DateTransformation(), // 日期格式化工具对象
      // 空开所有页面路径
      airSwitchRoutePaths: ['/airSwitch', '/aboutEquip', '/realtimeData',
        '/routeTiming', '/routeTimingDetails', '/historyCurve',
        '/brakeWarning', '/selfCheckDetail', '/selfCheckLogs', '/addOrEditSelfCheckTask'
      ],
      showAirSwitchMessage: false, // 显示空开弹窗
      airSwitchMessageIsGoing: false, // 是否有空开信息正在显示标识，开始默认是false 也就是没有消息在展示
      airSwitchMessageWaitQueue: [], // 空开消息等待队列
      currentAirSwitchMessage: null,
      airSwitchMessageTimer: null,
      showAirSwitchMessageJump: false,
      willJumpMessage: null
    }
  },
  computed: {
    changeDate: function() {
      return function(val) {
        return this.dateFormat(val)
      }
    },
    ...mapGetters([
      'realtimeEvent',
      'airSwitchBrakeWarningEvent',
      'leakageProtectInspectionEvent'
    ]),
    /**
     * @description: 是否开启弹窗
     * @param {*}
     * @return {*}
     */
    showModelSwitch() {
      return () => {
        return getAlarmModelSwitch()
      }
    },
    /**
     * @description: 是否在空开的页面里面
     * @param {*}
     * @return {*}
     */
    isAirSwitchPath() {
      return this.airSwitchRoutePaths.includes(this.$route.path)
    }
  },
  watch: {
    '$route'(to, from) {
      if (from.path === '/placeResourceAddition' && to.path === '/placeResource') {
        window.localStorage.removeItem('equipList')
        window.localStorage.removeItem('placeResource')
      }
      if (this.$route.path === '/login') {
        return
      }

      if ((from.path === '/login' || (from.path === '/' && to.path !== '/login')) && this.$route.path !== '/login') {
        this.initSockets()
      }
    },
    /**
     * @description: 实时事件
     * @param {*} val
     * @param {*} oldVal
     * @return {*}
     */
    async realtimeEvent(val, oldVal) {
      if (this.$route.path !== '/login') {
        let temp = val
        temp = await ReadTypeNameOnVuex.conversion('hazardType', 'hazardType', 'hazardTypeName', temp)
        if (this.showModelSwitch()) {
          this.hazardList.push(temp)
        }

        // 如果事件队列没有在执行,且事件队列数量不为0，就开启事件队列
        if (!this.working && this.hazardList.length > 0) {
          this.messageQueue(temp)
        }
      }
    },
    /**
     * @description: 智能空开-开关闸警示消息
     * @param {*} message
     * @return {*}
     */
    airSwitchBrakeWarningEvent(message) {
      if (this.isAirSwitchPath) {
        const airSwitchMessage = {
          messageType: 1, // 消息类型，根据此跳转两种不同页面
          equipId: message.id,
          leftIconImg: require(`@/assets/images/applicationSubsystem/${message.operation === 1 ? 'brake-open-icon' : 'brake-close-icon'}.png`),
          airSwitchBackground: message.operation === 1 ? '#143AA3' : '#E29506',
          title: `${message.operation === 1 ? '合闸' : '分闸'}警示`,
          time: this.dateTransformation.dataFormat(message.createdTime, 'yyyy-mm-dd hh-mm'),
          address: `${message.networkName}${message.installAddress}`,
          lines: message.lineNames.join('，')
        }
        this.addToAirSwitchQueue(airSwitchMessage)
      }
    },
    /**
     * @description: 智能空开-漏保自检消息
     * @param {*} message
     * @return {*}
     */
    leakageProtectInspectionEvent(message) {
      if (this.isAirSwitchPath) {
        const airSwitchMessage = {
          messageType: 0,
          equipId: message.id,
          leftIconImg: require(`@/assets/images/applicationSubsystem/${message.result === 0 ? 'self-check-success-icon' : 'self-check-fail-icon'}.png`),
          airSwitchBackground: message.result === 0 ? '#478816' : '#CE1616',
          title: `漏保自检结果${message.result === 0 ? '正常' : '异常'}`,
          time: this.dateTransformation.dataFormat(message.createdTime, 'yyyy-mm-dd hh-mm'),
          address: `${message.networkName}${message.installAddress}`,
          lines: ''
        }
        this.addToAirSwitchQueue(airSwitchMessage)
      }
    },
    /**
     * @description: 监听空开消息被关闭
     * @param {*} val
     * @return {*}
     */
    showAirSwitchMessage(val) {
      if (!val) {
        if (this.airSwitchMessageWaitQueue.length === 0) {
          this.airSwitchMessageIsGoing = false
        } else {
          if (this.airSwitchMessageTimer) {
            clearTimeout(this.airSwitchMessageTimer)
          }
          // 稍微延迟再弹出新的窗口
          setTimeout(() => {
            this.openAirSwitchMessage()
          }, 500)
        }
      }
    },
    '$route.path': {
      handler(val) {
        if (!this.airSwitchRoutePaths.includes(val)) {
          this.airSwitchMessageIsGoing = false
          this.currentAirSwitchMessage = null
          this.airSwitchMessageWaitQueue = []
          if (this.airSwitchMessageTimer) {
            clearTimeout(this.airSwitchMessageTimer)
          }
        }
      }
    }
  },
  created() {
    this.eventPicturePrefix = Config.eventPicturePrefix
  },
  mounted() {
    if (this.$route.path !== '/login' && this.$route.path !== '/') {
      this.initSockets()
    }
  },
  beforeDestroy() {
    if (this.airSwitchMessageTimer) {
      clearTimeout(this.airSwitchMessageTimer)
    }
    this.$socket.unsubscribe('airSwitchBrakeWarningEvent')
    this.$socket.unsubscribe('leakageProtectInspectionEvent')
  },
  methods: {
    /**
     *  点击 X 关闭消息蒙层
     */
    closeMessage() {
      // 如果事件队列长度还有最后一个
      if (this.hazardList.length < 2) {
        // 直接关闭消息蒙层
        this.show = false
        // 清除闪烁定时器
        clearInterval(this.flashingTimer)
        this.hazardList.splice(0, 1)
        this.working = false
      } else {
        // 删除事件队列第一个
        this.hazardList.splice(0, 1)
        // 清除闪烁定时器
        clearInterval(this.flashingTimer)
        this.messageQueue()
      }
      // 清除当前事件队列等待定时器 直接跳过当前执行下一个
      clearTimeout(this.waitTime)
    },
    /**
     * 初始化socket
     */
    initSockets() {
      if (this.$route.path !== '/login') {
        this.$socket.initSocket('event')
        this.$socket.initSocket('airSwitchBrakeWarningEvent')
        this.$socket.initSocket('leakageProtectInspectionEvent')
      }
    },
    /**
     * 时间格式转换
     */
    dateFormat(date) {
      const dateFormat = new DateTransformation()
      return dateFormat.dataFormatStamp(date)
    },
    /**
     * 点击跳转详情
     */
    toDetail(detailId) {
      this.show = false
      this.flashingTimer = 0
      clearInterval(this.flashingTimer)
      this.$router.push({
        path: '/hazardDetail',
        query: {
          hazardId: detailId
        }
      })
    },
    /**
     * 事件队列
     */
    messageQueue(msg) {
      if (this.$route.path !== '/login' && this.$route.path !== '/') {
      // 开启消息队列
        this.working = true
        // 打开消息蒙层
        this.show = true
        // 清除闪烁定时器
        clearInterval(this.flashingTimer)
        // 信息卡片赋值   永远取事件队列的第一条
        this.hazardMessage = this.hazardList[0]

        if (this.hazardList.length > 0) {
          if (this.hazardMessage.type === '1' || this.hazardMessage.type === '4' || this.hazardMessage.type === '2') {
            this.muteStatus = true
            this.haveTheMute = true
          } else {
            this.muteStatus = false
            this.haveTheMute = false
          }
        }

        // 闪烁定时器打开
        this.flashingTimer = setInterval(() => {
          this.flashingBox = this.flashingBox === 'hazard-message' ? 'hazard-message-light' : 'hazard-message'
        }, 300)

        // 消息队列定时器打开 十秒执行一次
        this.waitTime = setTimeout(() => {
        // 十秒之后执行删除第一条
          this.hazardList.splice(0, 1)
          // 事件队列还有值
          if (this.hazardList.length > 0) {
          // 递归调用执行消息队列
            this.messageQueue()
          // 如果事件队列空了
          } else {
          // 关闭蒙层
            this.show = false
            // 清除闪烁定时器
            clearInterval(this.flashingTimer)
            // 关闭消息队列执行状态
            this.working = false
            this.muteStatus = false
            this.haveTheMute = false
          }
        }, 10000)
      }
    },
    /**
     * 设备消音
     */
    async equipMute(id) {
      if (!this.haveTheMute) {
        return
      }
      this.haveTheMute = false

      const res = await AiotApi.equipMute(id)
      if (res.code === 200) {
        this.$toast.success('消音指令已下发')
      } else {
        this.$toast.fail('消音失败')
        this.haveTheMute = true
      }
    },
    /**
     * @description: 将信息加入空开信息队列
     * @param {*}
     * @return {*}
     */
    addToAirSwitchQueue(message) {
      // 没有消息在显示，就直接显示
      this.airSwitchMessageWaitQueue.push(message)
      if (!this.airSwitchMessageIsGoing) {
        this.openAirSwitchMessage()
      }
    },
    /**
     * @description: 打开空开消息
     * @param {*}
     * @return {*}
     */
    openAirSwitchMessage() {
      this.airSwitchMessageIsGoing = true
      this.showAirSwitchMessage = true
      this.currentAirSwitchMessage = this.airSwitchMessageWaitQueue[0]
      this.airSwitchMessageWaitQueue.shift()
      this.airSwitchMessageTimer = setTimeout(() => {
        this.showAirSwitchMessage = false
        if (this.airSwitchMessageWaitQueue.length !== 0) {
          this.openAirSwitchMessage()
        }
      }, 10000)
    },
    /**
     * @description: 关闭空开信息
     * @param {*}
     * @return {*}
     */
    closeAirSwitchMessage() {
      this.showAirSwitchMessage = false
      clearTimeout(this.airSwitchMessageTimer)
    },
    /**
     * @description: 点击空开消息
     * @param {*} message
     * @return {*}
     */
    handleMessageClick(message) {
      if (this.$route.path === '/addOrEditSelfCheckTask') {
        this.showAirSwitchMessageJump = true
        this.willJumpMessage = message
        return
      }
      this.toBrakeWarningOrSelfCheck(message)
    },
    /**
     * @description: 确认空开页面跳转
     * @param {*}
     * @return {*}
     */
    confirmAirSwitchJump() {
      this.toBrakeWarningOrSelfCheck(this.willJumpMessage)
    },
    toBrakeWarningOrSelfCheck(message) {
      if (message.messageType === 0) {
        // 自检页面
        if (this.$route.path === '/selfCheckDetail' && this.$route.query.equipId === message.equipId) {
          // 如果设备id和页面相同时不必再次跳转
          return
        }
        this.$router.push({
          path: '/selfCheckDetail',
          query: {
            equipId: message.equipId
          }
        })
      } else {
        // 跳转至分合闸警示页面
        if (this.$route.path === '/brakeWarning' && this.$route.query.equipId === message.equipId) {
          return
        }
        this.$router.push({
          path: '/brakeWarning',
          query: {
            equipId: message.equipId
          }
        })
      }
    }
  }
}
</script>

<style scoped>
.layout{
  width: 100%;
  height: auto;
}
.hazard-message-light{
  width: 73.6%;
  height: 154px;
  margin: 70% 13.2% 0px 13.2%;
  text-align: center;
  border: 1px solid #431B0E;
  background: #101720;
  transition:all 0.3s;
  box-shadow:0px 0px  12px 7px #431B0E;
}
.hazard-message{
  width: 73.6%;
  height: 154px;
  margin: 70% 13.2% 0px 13.2%;
  text-align: center;
  transition:all 0.3s;
  border: 1px solid #431B0E;

  background: #101720;
}
.close-button{
  float: right;
  margin:8px 8px 0px 0px;
}
.content{
  width: 100%;
  height: 72%;
  float: left;
}
.content-left{
  width:40%;
  height: 100%;
  float: left;
}
.content-right{
  width: 55%;
  padding-left: 2%;
  height: 74%;
  float: right;
  margin-top: 7%;
text-align: left;

}
.unread{
  width: 6px;
  height: 6px;
  background: #FF1743;
  border-radius: 50%;
  margin-top: 48%;
  margin-left: 30%;
  float: left;
}
.icon-box{
  width: 45px;
  height: 45px;
  float: left;
  margin-top: 30%;
  margin-left: 20px;
  /* background-color: rgba(40, 52, 68, 0.38); */
}
.icon{
  width: 45px;
  height: 45px;
}
.title{
  font-size: 15px;
  width: 80%;
  font-family: PingFang SC;
  overflow: hidden;
  font-weight: 500;
  color: #B9CEE9;
  text-align: left;

  white-space:nowrap;
}
.hazard-type{
  width: auto;
  height: 21px;
  display: inline-block;
  background: #2A1A1D;
  padding: 0px 5px 0px 5px;
  border: 1px solid #FF1743;
  border-radius: 2px;
  font-size: 12px;
  line-height: 21px;
  margin-top: 6px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #FF1743;
}
.date{
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #6F85A2;
  text-align: left;
  margin-top: 6px;
}
.foot{
  width: 100%;
  text-align: center;
  height: 42px ;
  line-height: 42px;
  float: left;
  clear: both;
  font-size: 13px;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  color: #6f85a2;
  border-top: 1px solid #283444; ;
}
.close{
  width: 46%;
  height: 42px;
  line-height: 42px;
  display: inline-block;
  border-right: 1px solid #283444;
}
.mute{
  width: 46%;
  height: 42px;
  line-height: 42px;
  display: inline-block;
  color: #FF1743;
}
.multiple{
  width: 100%;
  height: 42px;
  line-height: 42px;
  display: inline-block;
}

.message-container{
  width: 100%;
  display: flex;
}
.message-container .left{
  padding-top: 3px;
  width: 15px;
}
.message-container .right{
  width: 15px;
}
.message-container .right{
  padding-top: 15px;
  text-align: center;
  font-size: 19px;
}
.message-container .left img {
  width: 15px;
  height: 15px;
}
.message-container .center{
  flex: 1;
  padding-left: 15px;
  height: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.message-container .center .title{
  width: 100%;
  height: 21px;
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: 500;
  line-height: 21px;
  color: #FFFFFF;
}
.message-container .center .time-and-address{
  margin-top: 5px;
  width: 280px;
  height: 17px;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 17px;
  color: #FFFFFF;
  box-sizing: border-box;
  display: flex;
}
.message-container .center .time-and-address .time{
  display: inline-block;
  width: 140px;
  text-align: left;
}
.message-container .center .time-and-address .address{
  margin-left: 15px;
  display: inline-block;
  width: 150px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.message-container .center .lines{
  margin-top: 5px;
  width: 180px;
  height: 17px;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
<style>
/* 空开信息弹窗相关 start */
.layout .van-notify {
  padding: 10px 15px;
}

.layout-air-switch-dialog.van-dialog {
  background: #10161F;
  border-radius: 0;
}
.layout-air-switch-dialog.van-dialog .van-dialog__header{
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #B9CEE9;
}
.layout-air-switch-dialog.van-dialog .van-dialog__footer .van-button{
  background: #10161F;
}
.layout-air-switch-dialog.van-dialog  .van-hairline--top::after {
  border: 1px solid #27303B;
}
.layout-air-switch-dialog.van-dialog .van-dialog__cancel{
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #8BA3C2;
}
.layout-air-switch-dialog.van-dialog .van-dialog__confirm{
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #06F0FE;
}
.layout-air-switch-dialog.van-dialog .van-dialog__confirm::after {
  border-color: #27303B;
}
/* 空开信息弹窗相关 end */
</style>
