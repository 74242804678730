import request from '@/utils/request'

class Aiot {
  /**
   * 获取物联应用列表
   * @param {*} systemType 子系统类型
   * @param {*} params 查询条件
   * @param {*} page 页数
   * @param {*} size 条数
   * @returns
   */
  async equipInfoList(systemType, params, page = 1, size = 10) {
    return await request({
      url: `/apis/equip/type/${systemType}/place/page/${page}/size/${size}`,
      method: 'get',
      params
    })
  }
  /**
   * 获取物联应用实时数据列表(设备环境监测(单个) )
   * @param {*} id 设备id
   * @returns
   */
  async environmentRealTimeData(id) {
    return await request({
      url: `/apis/equip/id/${id}/environment`,
      method: 'get'
    })
  }
  /**
    * 获取塔机实时数据列表(设备塔吊实时数据-单个 )
    * @param {*} id
    * @returns
    */
  async towerRealTimeInfo(id) {
    return await request({
      url: `/apis/equip/id/${id}/tower`,
      method: 'get'
    })
  }
  /**
    * 获取烟温实时数据列表(设备塔吊实时数据-单个 )
    * @param {*} id 设备id
    * @param {*} params 所属子系统
    * @returns
    */
  async equipRealTimeDataInfo(id, params) {
    return await request({
      url: `/apis/equip/id/${id}/realtime`,
      method: 'get',
      params
    })
  }
  /**
   * 获取物联应用未处理事件数
   * @param {*} id
   * @returns
   */
  async equipUntreatedEvent(id) {
    return await request({
      url: `/apis/equip/id/${id}/event/undone/counting`,
      method: 'get'
    })
  }
  /**
   * 获取设备详情
   * @param {*} id
   * @returns
   */
  async equipDtailInfo(id) {
    return await request({
      url: `/apis/equip/id/${id}`,
      method: 'get'
    })
  }
  /**
    * 获取绑定日志列表
    * @param {*} id 设备id
    * @returns
    */
  async bindingLogList(id) {
    return await request({
      url: `/apis/equip/id/${id}/binding/log/list`,
      method: 'get'
    })
  }
  /**
   * 修改设备信息
   * @param {*} equipId 设备id
   * @param {*} data 表单数据
   * @returns
   */
  async updateEquip(equipId, data) {
    return await request({
      url: `/apis/equip/id/${equipId}/info`,
      method: 'put',
      data
    })
  }
  /**
   * 根据IMEI查找设备
   * @param {*} imei 设备imei码
   * @returns
   */
  async equipImei(imei) {
    return await request({
      url: `/apis/equip/type/imei/${imei}`,
      method: 'get'
    })
  }
  /**
   * 获取网点关联设备
   * @param {*} id 网点id
   * @returns
   */
  async placeResourceEquip(id) {
    return await request({
      url: `/apis/place/id/${id}/equip/list`,
      method: 'get'
    })
  }
  /**
   * 关联设备
   * @param {*} id 网点id
   * @param {*} imei 设备imei码
   * @param {*} data 表单数据
   * @returns
   */
  async bindEquip(id, imei, data) {
    return await request({
      url: `/apis/place/id/${id}/equip/imei/${imei}`,
      method: 'post',
      data
    })
  }
  /**
   * 解绑设备
   * @param {*} placeId 网点id
   * @param {*} equipId 设备id
   * @returns
   */
  async deleteEquip(placeId, equipId) {
    return await request({
      url: `/apis/place/id/${placeId}/equip/id/${equipId}`,
      method: 'delete'
    })
  }
  /**
   * 应用列表
   * @returns
   */
  async applicationlist() {
    return await request({
      url: `/apis/equip/status/counting/list`
    })
  }
  /**
   * 设备总数/在线数/离线数
   * @param {*} params 查询条件
   * @returns
   */
  async equipCountings(params) {
    return await request({
      url: `/apis/equip/countings`,
      method: 'get',
      params
    })
  }
  /**
   * 设备在线率（仪表盘，码表）
   * @returns
   */
  async onlinePercent() {
    return await request({
      url: '/apis/equip/online/percent',
      method: 'get'
    })
  }
  /**
   * 网格统计-设备状态
   * @param {*} id 网格id
   * @returns
   */
  async departEquipCounting(id) {
    return await request({
      url: `/apis/depart/id/${id}/equip/status/counting`,
      method: 'get'
    })
  }
  /**
   * 获取设备类型列表
   * @param {*} systemType
   * @returns
   */
  async equipTypeList(systemType) {
    return await request({
      url: `/apis/equip/type/${systemType}/type/list`,
      method: 'get'
    })
  }
  /**
   * 获取子设备类型（国标类型）
   * @param {*} type 系统类型
   * @returns
   */
  async nationalStandardType(type) {
    return await request({
      url: `/apis/equip/type/${type}/child/type/list`,
      method: 'get'
    })
  }

  /**
   * 根据id获取设备消音状态
   * @param {*} id 设备id
   * @returns
   */
  async equipMuteStatus(id) {
    return await request({
      url: `/apis/equip/id/${id}/mute/status`,
      method: 'get'
    })
  }
  /**
    * 根据id把设备消音
    * @param {*} id 设备id
    * @returns
    */
  async equipMute(id) {
    return await request({
      url: `/apis/equip/id/${id}/mute/status`,
      method: 'put'
    })
  }
  /**
   * 用传装置子设备列表
   * @param {*} id 设备id
   * @param {*} page 页数
   * @param {*} size 条数
   * @param {*} params params参数
   * @returns
   */
  async fireEquip(id, page, size, params) {
    return await request({
      url: `/apis/equip/id/${id}/child/list/page/${page}/size/${size}`,
      method: 'get',
      params
    })
  }
  /**
   * 添加子设备
   * @param {*} id
   * @param {*} data
   * @returns
   */
  async addChildEquip(id, data) {
    return await request({
      url: `/apis/equip/id/${id}/child`,
      method: 'post',
      data
    })
  }
  /**
   * 获取阈值-温湿度监测
   * @param {*} id 设备id
   * @returns
   */
  async threshold(id) {
    return await request({
      url: `/apis/equip/id/${id}/humiture/threshold`,
      method: 'get'
    })
  }
  /**
    * @name:
    * @param {type} id
    * @param {type} params
    * @return {type}
    */
  async openTheDoorLog(id, params) {
    return await request({
      url: `/apis/equip/id/${id}/doorsensor/log`,
      method: 'get',
      params
    })
  }
  /**
   * @name: 获取充电桩子设备列表
   * @param {type} id 主设备id
   * @return {type}
   */
  async getChargingPileChildEquipList(id) {
    return await request({
      url: `/apis/equip/id/${id}/chargingpile/list`,
      method: 'get'
    })
  }

  async bindChildEquip(mainId, data) {
    return await request({
      url: `/apis/equip/id/${mainId}/chargingpile`,
      method: 'post',
      data
    })
  }
  // =============================== 智能空开 ================================
  /**
   * @name: 获取通讯模组
   * @param {type} id
   * @return {type}
   */
  async communicateModule(id) {
    return await request({
      url: `/apis/equip/id/${id}/smartairswitch/communication/module`,
      method: 'get'
    })
  }
  /**
   * @name: 获取电源模组列表
   * @param {type} id
   * @return {type}
   */
  async powerSupplyModule(id) {
    return await request({
      url: `/apis/equip/id/${id}/smartairswitch/power/module/list`,
      method: 'get'
    })
  }
  /**
   * @name: 空开线路列表
   * @param {type} id 空开设备id
   * @param {type} page 页数
   * @param {type} size 条数
   * @return {type}
   */
  async wisdomAirSwitchLineList(id, page = 1, size = 10) {
    return await request({
      url: `/apis/equip/id/${id}/smartairswitch/line/list/page/${page}/size/${size}`,
      method: 'get'
    })
  }
  /**
   * @name: 空开阈值列表
   * @param {type} id 空开设备id
   * @param {type} page 页数
   * @param {type} size 条数
   * @return {type}
   */
  async wisdomAirSwitchThresholdList(id, page = 1, size = 10) {
    return await request({
      url: `/apis/equip/id/${id}/smartairswitch/line/threshold/list/page/${page}/size/${size}`,
      method: 'get'
    })
  }
  /**
   * @name: 编辑线路
   * @param {type} equipId 空开设备id
   * @param {type} lineId 线路id
   * @param {type} data 表单数据
   * @return {type}
   */
  async updateLine(equipId, lineId, data) {
    return await request({
      url: `/apis/equip/id/${equipId}/smartairswitch/line/id/${lineId}`,
      method: 'put',
      data
    })
  }
  /**
   * @name: 检查电源模块
   * @param {type} id 空开设备id
   * @param {type} sn 设备sn码
   * @return {type}
   */
  async checkHasPowerSupply(id, sn) {
    return await request({
      url: `/apis/equip/id/${id}/smartairswitch/power/module/sn/code/${sn}/check`,
      method: 'get'
    })
  }
  /**
   * @name: 删除电源模块
   * @param {type} id 空开设备id
   * @param {type} sn 设备识别码
   * @return {type}
   */
  async deletePowerSupply(id, sn) {
    return await request({
      url: `/apis/equip/id/${id}/smartairswitch/power/module/sn/code/${sn}`,
      method: 'delete'
    })
  }
  /**
   * @name: 新增电源模块
   * @param {type} id 空开设备id
   * @param {type} data 表单数据
   * @return {type}
   */
  async addPowerSupply(id, data) {
    return await request({
      url: `/apis/equip/id/${id}/smartairswitch/power/module`,
      method: 'post',
      data
    })
  }
  /**
   * @description: 修改阈值
   * @param {*} id
   * @param {*} lineId
   * @param {*} data
   * @return {*}
   */
  async updateThreshold(id, lineId, data) {
    return await request({
      url: `/apis/equip/id/${id}/smartairswitch/line/id/${lineId}/threshold`,
      method: 'put',
      data
    })
  }
  /**
   * @description: 空开阈值信息
   * @param {*} id
   * @param {*} lineId
   * @return {*}
   */
  async wisdomAirSwitchThreshold(id, lineId) {
    return await request({
      url: `/apis/equip/id/${id}/smartairswitch/line/id/${lineId}/threshold`,
      method: 'get'
    })
  }
  /**
   * @description: 数字哨兵功能列表
   * @param {*} id
   * @return {*}
   */
  async digitalsentrySettings(id) {
    return await request({
      url: `/apis/equip/id/${id}/digitalsentry/settings`,
      method: 'get'
    })
  }
  /**
   * @description: 编辑线路设置(仅用作连接方式和远程遥控修改)
   * @param {*} id
   * @param {*} lineId
   * @param {*} data
   * @return {*}
   */
  async updateThresholdSet(id, lineId, data) {
    return await request({
      url: `/apis/equip/id/${id}/smartairswitch/line/id/${lineId}/set`,
      method: 'put',
      data
    })
  }
  /* ================== 水压液位监测 ==================*/
  /**
   * @description:  获取用水阈值
   * @param {String} equipId 设备id
   * @return {*}
   */
  async getThresholdOfLiquid(equipId) {
    return await request({
      url: `/apis/equip/id/${equipId}/usewater/threshold`,
      method: 'get'
    })
  }
  /* ================== 室外消防栓 ==================*/
  /**
   * @description: 获取消防栓的阈值
   * @param {String} equipId 设备id
   * @return {*}
   */
  async getThresholdOfHydrant(equipId) {
    return await request({
      url: `/apis/equip/id/${equipId}/hydrant/hydraulic`,
      method: 'get'
    })
  }
}
export default new Aiot()
