/*
 * @Author: your name
 * @Date: 2021-12-01 16:07:09
 * @LastEditTime: 2021-12-01 16:07:09
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /webwechatpublicend/src/utils/imei.js
 */

export default class Imei {
  constructor(imei) {
    this.imei = imei
  }

  decode() {
    const imei = this.imei
    // TODO: 海康威视 (烟感) (第一代)
    // 06&SN开头, -N开始为 imei码
    if (imei.indexOf('06&SN') !== -1 && imei.lastIndexOf('-N') !== -1) {
      if (imei.indexOf('-N')) {
        const startIndex = imei.indexOf('-N') + 2
        const endIndex = imei.length - 1

        const result = imei.substring(startIndex, endIndex)

        return result
      }
    }

    // TODO: 海康威视 (烟感) (第二代)
    // CODE开头
    if (imei.indexOf('CODE') !== -1) {
      // 第一个逗号开始取值
      if (imei.indexOf(',')) {
        const startIndex = imei.indexOf(',') + 1
        const endIndex = imei.length + 1

        const result = imei.substring(startIndex, endIndex)

        return result
      }
    }

    // TODO: 中消云 (烟感)
    // 01_NB_开头
    if (imei.indexOf('01_NB_') !== -1) {
      return imei
    }

    // TODO: 赛特威尔 (烟感)
    // JSON格式, {}开头结尾
    if (imei.indexOf('{') !== -1 && imei.lastIndexOf('}') !== -1) {
      const result = JSON.parse(imei).i
      if (result) {
        return String(result)
      }
    }

    // TODO: 三江 (烟感)
    // IMSI结束, 直接 86开头
    if (imei.indexOf('86') !== -1) {
      return imei
    }
  }
}
