import request from '@/utils/request'

class Depart {
  /**
   * 获取网格树接口
   * @returns
   */
  async getDepartTree() {
    return await request({
      url: `/apis/depart/tree`,
      method: 'get'
    })
  }
  /**
   * 获取自己的辖区列表
   * @returns
   */
  async departList() {
    return await request({
      url: `/apis/depart/list`,
      method: 'get'
    })
  }
  /**
   * 网格统计-网格列表
   * @returns
   */
  async departCountingList() {
    return await request({
      url: `/apis/depart/list/size/10`,
      method: 'get'
    })
  }
}
export default new Depart()
