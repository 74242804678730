import Config from '/config.json'
import WeChatApi from '@/api/public/weChat'
import sha1 from 'sha1'
import wx from 'weixin-js-sdk'
import Imei from '@/utils/imei'
import { Toast } from 'vant'
let appId = ''
let nonceStr = ''
let wxApiEnable = false
class Wechat {
  constructor() {
    appId = Config.wx.appId
    nonceStr = Config.wx.nonceStr
    wxApiEnable = Config.wx.enable
  }
  /**
   * @name: 初始化socket
   * @param {String} ticket 微信用于调用 jssdk的票据
   * @return {type}
   */
  async weChatValidation(ticket) {
    // 生成签名
    const signature = sha1(
      'jsapi_ticket=' + ticket + '&noncestr=' + nonceStr + '&timestamp=1630388782057' + '&url=' + location.href.split('#')[0]
    )
    // 初始化微信
    // 在此之前需要确认微信公众平台的js接口域名、页面授权域名
    // 是否配置了当前你在使用测试的域名
    // 否则会报 config:fail,Error: 系统错误，错误码：40048,invalid url domain
    // 时间戳和nonceStr这两个字段，在签名中的和config参数中的值需要一致
    wx.config({
      // debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来
      appId, // 必填，公众号的唯一标识
      timestamp: '1630388782057', // 必填，生成签名的时间戳 随机 => 随意 秒和毫秒都可
      nonceStr, // 生成签名的随机串 直接用之前的，不需要再重新生成
      signature, // 签名
      jsApiList: [
        'openLocation',
        'getLocation',
        'scanQRCode',
        'chooseWXPay'
      ] // 必填，需要使用的JS接口列表
    })
    wx.error(function(res) {
    })
    wx.ready(() => {
      // 检查api
      wx.checkJsApi({
        jsApiList: [
          'openLocation',
          'getLocation',
          'scanQRCode',
          'chooseWXPay'
        ],
        // 需要检测的JS接口列表，所有JS接口列表见附录2,
        success: (res) => {
        },
        fail: (res) => {
        }
      })
    })
  }
  /**
   * @name: 调起扫码
   * @param {type} callBack 回调函数
   * @return {type}
   */
  scanQRCode(callBack) {
    if (wxApiEnable) {
      wx.scanQRCode({
      // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
        needResult: 1,
        // 可以指定扫二维码还是一维码，默认二者都有
        scanType: ['qrCode', 'barCode'],
        success: function(res) {
        // 当needResult 为 1 时，扫码返回的结果
          const result = res.resultStr
          // 判断是否为潮庭提供的二维码
          // 不同的二维码格式不同，所以需要不一样的处理
          if (result.indexOf('weixin') !== -1) {
            const resArr = result.split('imei=')
            const code = resArr[1]
            this.imei = resArr[1]
            // 用回调函数给到页面imei码
            callBack(code)
          } else {
            const index = result.indexOf('=')
            this.imei = result.substring(index + 1, result.length)
            const imei = new Imei(this.imei)
            // 不是潮庭提供的二维码的时候，扫码结果会因为不同的品牌不同的设备不一样
            // 所以我们这里需要做对应品牌
            const decodeResult = imei.decode()
            const code = decodeResult.replace(/\s*/g, '').trim()
            callBack(code)
          }
          return res.resultStr // 当needResult 为 1 时，扫码返回的结果
        }
      })
    }
  }
  /**
   * @name: 获取定位
   * @param {function} callBack
   */
  async getLocation(callBack) {
    if (wxApiEnable) {
      await wx.getLocation({
        type: 'wgs84',
        isHighAccuracy: true,
        altitude: true,
        success(res) {
          const location = {
            latitude: '',
            longitude: '',
            speed: '',
            accuracy: ''
          }
          const bMap = [res.longitude, res.latitude]
          location.latitude = bMap.lat // 纬度，浮点数，范围为90 ~ -90
          location.longitude = bMap.lng // 经度，浮点数，范围为180 ~ -180。
          location.speed = res.speed // 速度，以米/每秒计
          location.accuracy = res.accuracy // 位置精度
          callBack(bMap)
        },
        cancel(res) {
        },
        fail(res) {
          let equipmentType = ''
          const agent = navigator.userAgent.toLowerCase()
          const android = agent.indexOf('android')
          const iphone = agent.indexOf('iphone')
          const ipad = agent.indexOf('ipad')
          if (android !== -1) {
            equipmentType = 'android'
          }
          if (iphone !== -1 || ipad !== -1) {
            equipmentType = 'ios'
          }
          if (equipmentType !== 'ios') { // 安卓再调用一次
            wx.getLocation({
              type: 'wgs84',
              isHighAccuracy: true,
              altitude: true,
              success(res) {
                const location = {
                  latitude: '',
                  longitude: '',
                  speed: '',
                  accuracy: ''
                }
                const bMap = [res.longitude, res.latitude]
                location.latitude = bMap.lat // 纬度，浮点数，范围为90 ~ -90
                location.longitude = bMap.lng // 经度，浮点数，范围为180 ~ -180。
                location.speed = res.speed // 速度，以米/每秒计
                location.accuracy = res.accuracy // 位置精度
                callBack(bMap)
              },
              cancel(res) {
              },
              fail(res) {
                Toast.fail('获取当前定位失败，请刷新重试！')
                callBack([113.54342, 22.26666])
              }
            })
          } else {
            Toast.fail('获取当前定位失败，请刷新重试！')
            callBack([113.54342, 22.26666])
          }
        }
      })
    }
  }
  /**
   * @name:
   * @param {Object} res
   */
  openLocation(res) {
    if (wxApiEnable) {
      wx.openLocation({
        latitude: res.lat, // 目的地latitude
        longitude: res.lng, // 目的地longitude
        name: res.name,
        address: res.address,
        scale: 15 // 地图缩放大小，可根据情况具体调整
      })
    }
  }
  /**
   * @name: 获取微信token和ticket
   */
  async getWxToken() {
    if (wxApiEnable) {
      const res = await WeChatApi.getWeChatToken()
      this.weChatValidation(res.data.ticket)
    }
  }
  /**
   * @name: 调起微信支付
   * @param {object} params
   * @param {function} callBack
  */
  openWxPay(params, callback) {
    if (wxApiEnable) {
      wx.chooseWXPay({
        timestamp: params.timestamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
        nonceStr: params.nonceStr, // 支付签名随机串，不长于 32 位
        package: params.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
        signType: params.signType, // 微信支付V3的传入RSA,微信支付V2的传入格式与V2统一下单的签名格式保持一致
        paySign: params.paySign, // 支付签名
        success(res) { // 支付成功后的回调函数
          if (res.errMsg === 'chooseWXPay:ok') {
            callback(res)
          }
        },
        fail(res) { // 支付失败

        },
        cancel(res) { // 支付取消

        }
      })
    }
  }
}
export default Wechat
